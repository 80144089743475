import Request from '../utils/request';
// import Configs from '../configs';

// Edit the suffix base on the version of your server API
const endpoint = `${process.env.REACT_APP_API_URL}/api`;

// Edit the suffix base on the version of your authentication API
const authEndpoint = `${process.env.REACT_APP_AUTHENTICATION_API_URL}/api`;

// Endpoint that using to access server APIs
const MainApi = Request.create({
  endpoint,
  handleToken: true,
});

// Endpoint that using to access external APIs
const ExternalApi = Request.create({
  endpoint: '',
});

// Endpoint that using to authentication
const AuthApi = Request.create({
  endpoint: authEndpoint,
  handleToken: true,
});

// Endpoint that using to download file which will response a Blob object
const BlobApi = Request.create({
  endpoint,
  handleToken: true,
  handleBlob: true,
});

export { MainApi, ExternalApi, BlobApi, AuthApi };
