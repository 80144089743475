import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const MessageContext = createContext({
  messages: [],
  addMessage: () => null,
  clearMessages: () => null,
  isLoading: false,
  setIsLoading: () => null,
});

export function StatusProvider({ children }) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const addMessage = useCallback((operation, ...args) => {
    let messageString;
    if (!args?.length) {
      messageString = operation;
    } else if (args[0] instanceof Error) {
      messageString = `${operation}: ${args[0].type} - ${args[0].message}`;
    } else {
      messageString = `${operation}: ${args
        .map(arg => {
          if (arg instanceof Error) {
            return arg.toString();
          }
          return JSON.stringify(arg, null, 2);
        })
        .join(' - ')}`;
    }
    setMessages(prevMessages => [...prevMessages, messageString]);
  }, []);

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, [setMessages]);

  const providerValues = useMemo(
    () => ({
      messages,
      addMessage,
      clearMessages,
      isLoading,
      setIsLoading,
    }),
    [messages, addMessage, clearMessages, isLoading, setIsLoading],
  );

  return <MessageContext.Provider value={providerValues}>{children}</MessageContext.Provider>;
}

export function useStatusProvider() {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useStatusProvider must be used within a StatusProvider');
  }
  return context;
}
