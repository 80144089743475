import { Button as AntButton } from 'antd';
import classnames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';

import { Images } from '../theme';
import TextShadow from './text-shadow';

const StyledButton = styled(AntButton)`
  /* position: relative;
  width: ${props => props.w || '100%'};
  height: ${props => props.h || '46px'};
  border: none;
  padding: 0;
  background-color: transparent;

  background-image: ${props => `url(${`${props.bg}`})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &:hover,
  &:focus,
  &:active {
    background-image: ${props => `url(${`${props.bg}`})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
  }
  &:disabled {
    cursor: not-allowed;
    background-image: ${props => `url(${`${props.bg}`})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
      background-image: ${props => `url(${`${props.bg}`})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: transparent;
    }
  } */
  position: relative;
  width: ${props => props.w || '100%'};
  height: ${props => props.h || '46px'};
  border: 2px solid #000000;
  border-radius: 8px;
  background-color: ${props => props.bgColor};
  background-image: none;
  .icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .layer-btn-1 {
    background-color: ${props => props.colorLayer1};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  .layer-btn-2 {
    background-color: ${props => props.colorLayer2};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 3px);
    border-radius: 6px;
  }
  .layer-btn-3 {
    background-color: ${props => props.colorLayer3};
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: calc(100% - 2px);
    border-radius: 6px;
  }

  ${props => {
    if (props.colorBgTop) {
      return css`
        &::after {
          content: '';
          position: absolute;
          top: 3px;
          left: 50%;
          width: calc(100% - 2px);
          height: 40%;
          transform: translateX(-50%);
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          background-color: ${props => props.colorBgTop};
        }
      `;
    }
  }}

  &:hover,
    &:focus,
    &:active {
    border: 2px solid #000000;
    border-radius: 8px;
    background-color: ${props => props.bgColor};
    background-image: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-image: none;
    border: 2px solid #000000;
    background-color: ${props => props.bgColor};

    &:hover,
    &:focus,
    &:active {
      background-image: none;
      border: 2px solid #000000;
      background-color: ${props => props.bgColor};
    }
  }
  .wrap-text-content {
    display: flex;
    background-color: ${props => props.colorWrapContent};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 2px);
    border-radius: 6px;
    ${props => {
      if (props.colorBgTop) {
        return css`
          &::after {
            content: '';
            position: absolute;
            top: 2px;
            left: 4px;
            width: 5px;
            height: 5px;
            transform: translateX(-50%) skew(-10deg);
            border-radius: 50%;
            background-color: #deffba;
            z-index: 5;
          }
        `;
      }
    }}
  }
  .text-content {
    z-index: 10;
  }
`;

const ButtonType = ({
  children,
  content,
  iconContent,
  w,
  h,
  colorText,
  fontSize,
  fontWeight,
  typeBtn,
  isShowTextShadow = true,
  text,
  className,
  ...props
}) => {

  const bgBtn = {
    bg: Images.BTN_GREEN,
    bgColor: '#12f056',
    colorBox: '#006553',
    colorBgTop: '#93faa1',
    colorLayer1: '#05aa62',
    colorLayer2: '#bbffde',
    colorLayer3: '#56f88c',
    colorWrapContent: '#11f055',
  }
  return (
    <StyledButton {...props} {...bgBtn} className={classnames(className)}>
      <div className="layer-btn-1">
        <div className="layer-btn-2">
          <div className="layer-btn-3">
            <div className="wrap-text-content">
              {iconContent && <img className="icon" src={iconContent} alt="icon" />}
              <TextShadow
                className="text-content"
                colorText={colorText || bgBtn.colorText}
                fontSize={fontSize}
                fontWeight={fontWeight}
                colorBox={isShowTextShadow ? bgBtn.colorBox : null}
              >
                {children}
              </TextShadow>
              {content && content()}
            </div>
          </div>
        </div>
      </div>
    </StyledButton>
  );
};

export default ButtonType;
