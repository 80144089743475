import PropTypes from 'prop-types';
import { useState } from 'react';

import styled from 'styled-components';
import Loading from '../components/loading';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { Colors } from '../theme';

const Init = ({ children }) => {
  const { isLoading } = useWeb3Auth();
  const [initing] = useState(true);

  return !initing || !isLoading ? (
    children
  ) : (
    <PreloadingStyled>
      <Loading size="full-screen" />
    </PreloadingStyled>
  );
};

Init.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Init;

const PreloadingStyled = styled.div`
  background-color: ${Colors.BOX_BACKGROUND};
`;
