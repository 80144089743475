import React from 'react';
import { Web3AuthProvider } from './contexts/web3auth/web3auth';

function Providers({ children }) {
  return (
    <Web3AuthProvider>{children}</Web3AuthProvider>
  );
}

export default Providers;
