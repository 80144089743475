import React from 'react';

import './resources/styles';
import Store from './store';
import Theme from './theme';
import Init from './app/init';
import Routes from './app/routes';
import Confirmable from './components/confirmable';
import './translations/i18n';

import './App.less';
import Providers from './Providers';
import MaskLoading from './components/mask-loading';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n';
import { StatusProvider } from './contexts/StatusProvider';

const App = () => {

  return (
    <Store>
      <Theme>
        <Providers>
          <StatusProvider>
            <Init>
              <I18nextProvider i18n={i18n}>
                <Routes />
              </I18nextProvider>
            </Init>
            <MaskLoading ref={ref => MaskLoading.setInstance(ref)} />
            <Confirmable ref={ref => Confirmable.setInstance(ref)} />
          </StatusProvider>
        </Providers>
      </Theme>
    </Store>
  );
};

export default App;
