import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { BUTTON_CLOSE_ICON } from '../theme/images';

const ModalColor = {
  black: {
    mainColor: '#131313',
    headerColor: '#151719',
    divierColor: '#232B38',
    topColor: '#333639',
    bottomColor: '#0D0F10',
  },
  blue: {
    mainColor: '#1DADFE',
    headerColor: '#0E7FF2',
    divierColor: '#0E69F2',
    topColor: '#24A2F6',
    bottomColor: '#1184DD',
  },
  white: {
    mainColor: '#FFFFFF',
    headerColor: '#0E7FF2',
    divierColor: '#0E69F2',
    topColor: '#24A2F6',
    bottomColor: '#B1C2DE',
  },
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent !important;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin: auto;

    .ant-modal-close {
      right: 20px;
      background: transparent;
      .ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        position: absolute;
        top: 7px;
        right: -16px;

        .icon-close {
          width: 32px;
          height: 32px;
        }
      }
    }

    .ant-modal-body {
      padding: ${({ padding }) => padding ?? 30}px;
    }
  }

  &.custom-modal {
    font-family: 'Noto Sans JP', sans-serif;
    .ant-modal-header {
      background-color: #040405;
      border-bottom: none;
      .title {
        color: #fff !important;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
    }
    &.visible {
      .ant-modal-content {
        overflow: visible;
        .ant-modal-close {
          display: none;
        }
      }
    }
    .ant-modal-content {
      background-color: ${props => `${ModalColor[props.color]?.mainColor}`} !important;
      border: 1px solid #1a1a1a;
      border-radius: 16px;
      border: 2px solid black;
      box-shadow: ${props => `${ModalColor[props.color]?.bottomColor}`} 0px -8px 0px inset,
        0px 8px 0px rgba(0, 0, 0, 0.2);
      .title {
        color: #040c14;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #3cc8fc;
  }
`;

export const HeaderStyled = styled.div`
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${props => `${ModalColor[props.color]?.headerColor}`};
  border-bottom: 2px solid ${props => `${ModalColor[props.color]?.divierColor}`};
  border-radius: 15px 15px 0px 0px;
  box-shadow: inset 0px 3px 0px 0px ${props => `${ModalColor[props.color]?.topColor}`};
  .upppercase {
    text-transform: uppercase;
  }
  .title-header {
    z-index: 2;
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    position: relative;
    text-shadow: 1px 2px 1px black;
    &::after {
      content: attr(data-text);
      position: absolute;
      bottom: 2px;
      left: 0px;
      color: white;
    }
    @supports (-webkit-text-stroke: 2px #000000) {
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #000000;
      paint-order: stroke fill;
    }
  }
`;

export const ContentStyled = styled.div`
  padding: 12px 24px 24px;
  position: relative;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .label-email {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
  }
`;

const ModalComponent = ({ color = 'blue', children, title, className, width, ...props }) => (
  <StyledModal
    centered
    width={width ?? 600}
    footer={null}
    color={color}
    closeIcon={<img className="icon-close" src={BUTTON_CLOSE_ICON} alt="icon" />}
    className={className}
    title={title}
    {...props}
  >
    {children}
  </StyledModal>
);

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.object,
  className: PropTypes.string,
};

export default ModalComponent;
