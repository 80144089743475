import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import React from 'react';

import { Colors } from '../theme';

const StyledP = styled.p`
  text-align: ${props => props.align || 'left'};
  font-weight: ${props => (props.medium ? '600' : props.bold ? 'bold' : 'normal')};
  font-size: 14px;
  color: ${Colors.TEXT};

  &.tiny {
    font-size: 11px;
  }

  &.small {
    font-size: 12px;
  }

  &.oversmall {
    font-size: 13px;
  }

  &.large {
    font-size: 16px;
  }

  &.big {
    font-size: 18px;
  }

  &.huge {
    font-size: 20px;
  }

  &.giant {
    font-size: 24px;
  }

  &.enormous {
    font-size: 49px;
  }

  &.link {
    cursor: pointer;
    user-select: none;
  }

  &.underline {
    text-decoration: underline;
  }

  &.primary {
    color: ${Colors.PRIMARY};
  }

  &.secondary {
    opacity: 0.35;
  }

  &.center {
    text-align: center;
  }

  &.poppins {
    font-family: 'Poppins', sans-serif;
  }
`;

const Typography = ({
  className,
  children,
  link,
  underline,
  primary,
  secondary,
  center,
  size,
  poppins,
  bold,
  ...props
}) => (
  <StyledP
    className={classnames(
      size || '',
      {
        link,
        underline,
        primary,
        secondary,
        center,
        poppins,
      },
      'typography',
      bold && 'bold',
      className,
    )}
    {...props}
  >
    {children}
  </StyledP>
);
Typography.propTypes = {
  size: PropTypes.string,
  link: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  underline: PropTypes.bool,
  center: PropTypes.bool,
  poppins: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  bold: PropTypes.bool,
};

export default Typography;
