import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import lodash from 'lodash';

import { events } from '../services';
import validationMessages from './validation-messages.json';
import common from './common.json';
import auth from './auth.json';
import { STORAGE, getLocalStorage } from '../utils/storage';

const INIT_LANGUAGE = getLocalStorage(STORAGE.CURRENT_LANG) || 'en';
const resources = lodash.merge(
  validationMessages,
  common,
  auth,
);

i18n.use(initReactI18next).init({
  resources,
  lng: INIT_LANGUAGE,
  fallbackLng: INIT_LANGUAGE,
  preload: [INIT_LANGUAGE],
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

i18n.off('languageChanged');
i18n.on('languageChanged', language => {
  events.languageChange.emit(language);
});

export default i18n;
