import styled, { css } from 'styled-components';

const WrapTextShadow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: transparent;
`

const TextShadowStyled = styled.div`
  position: relative;
  font-weight: ${props => props.fontWeight || '700'};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  font-size: ${props => props.fontSize || '12px'};
  color: ${props => props.colorBox || '#000000'};
  width: fit-content;
  margin: auto;
  line-height: normal;
  ${props => {
    if (props.colorBox) {
      return css`
        &::after {
          content: attr(data-text);
          position: absolute;
          bottom: 2px;
          left: 0px;
          color: ${props => props.colorText || '#ffffff'};
        }

        @supports (-webkit-text-stroke: 2px #006553) {
          -webkit-text-stroke-width: ${props => props.strokeText || '2px'};
          -webkit-text-stroke-color: ${props => props.colorBox || '#000000'};
          paint-order: stroke fill;
        }
      `;
    }
  }}
`;

export default function TextShadow({
  strokeText,
  fontSize,
  fontWeight,
  colorText,
  colorBox,
  children,
  uppercase,
  ...rest
}) {
  return (
    <WrapTextShadow>
      <TextShadowStyled
        strokeText={strokeText}
        fontWeight={fontWeight}
        fontSize={fontSize}
        colorText={colorText}
        colorBox={colorBox}
        uppercase={uppercase}
        {...rest}
        data-text={children}
      >
        {children && children}
      </TextShadowStyled>
    </WrapTextShadow>
  );
}
